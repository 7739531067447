import React, { useState, useEffect } from 'react';
import { useMediaQuery, useDisclosure } from '@mantine/hooks';
import { createStyles, Text, Image, Flex} from '@mantine/core';
import ranLogo from "../images/Header/ranLogo.png"
import smallLogo from "../images/Header/smallLogo.png"
import AnchorLink from 'react-anchor-link-smooth-scroll';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: 'rgba(17, 25, 40, 0.30)',
    borderRadius: 4,
    alignItems: "center" ,
    backdropFilter: 'blur(16px) saturate(180%)',
    WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
  },
  headerText: {
    fontWeight: "bold",
    fontFamily: "Arial Rounded MT Bold"
  },
  anchor: {
    textDecoration: "none",
    color: "white"
  },
  stickyImage: {
    width: "100px !important",
    maxWidth: "100%",
    transition: "all 0.5s ease-in-out",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    cursor: "pointer",
    "@media (max-width: 730px)": {
      width: "50px !important",
    }
  },
  nonStickyImage: {
    cursor: "pointer",
    width: "170px !important",
    transition: "all 0.2s ease-in-out",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    "@media (max-width: 730px)": {
      width: "70px !important",
    },

  }

}));

export default function Header() {
  const { classes } = useStyles();
  const [isSticky, setIsSticky] = useState(false);
  const isXs = useMediaQuery('(max-width: 730px)');

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Flex
      justify="space-between"
      align="center"
      className={isSticky ? classes.header : ''}
    >
      <Flex>
        <Image src={isXs? smallLogo : ranLogo} className={isSticky ? classes.stickyImage : classes.nonStickyImage} onClick={()=> { window.scrollTo({ top: 0, behavior: 'smooth' });}} />
      </Flex>

      <Flex>
        <Text
          fz={isXs? "sm" : "xl"}
          sx={{ color: "white" }}
          px={isXs ? 5 : 30}
          className={classes.headerText}
        >
         <AnchorLink href="#about" offset={100} className={classes.anchor}>About</AnchorLink>
        </Text>
        <Text
         fz={isXs? "sm" : "xl"}
          sx={{ color: "white" }}
          px={isXs ? 5 : 30}
          className={classes.headerText}
        >
           <AnchorLink href="#skills" offset={100} className={classes.anchor}>Skills</AnchorLink>
        </Text>
        <Text
          fz={isXs? "sm" : "xl"}
          sx={{ color: "white" }}
          px={isXs ? 5 : 30}
          mr={isXs? 20 : 0}
          className={classes.headerText}
        >
           <AnchorLink href="#contact" offset={100} className={classes.anchor}>Contact Us</AnchorLink>
        </Text>
      </Flex>

    </Flex>
  );
}

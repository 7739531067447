import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Text, Image, Flex, Title, Grid } from '@mantine/core';
import DeveloperImage from "../images/About/developer.png"
import GetThingsDoneImage from "../images/About/getThingsDone.png"
import StayInTouchImage from "../images/About/stayInTouch.png"
import { Carousel } from '@mantine/carousel';
import BrandImage from "../images/About/brandImage2.svg"
import RoadMapImage from "../images/About/roadMap.png"
import { ReactSVG } from "react-svg";

const useStyles = createStyles(theme => ({
    section: {
        backgroundColor: 'rgba(17, 25, 40, 0.25)',
        borderRadius: 12,
        border: '1px solid rgba(255, 255, 255, 0.125)',
        backdropFilter: 'blur(16px) saturate(180%)',
        WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    },
    aboutTitle: {
        color: "white",
        fontFamily: "Arial Rounded MT Bold",
        textTransform: "uppercase",
    }
}));

function Animation() {
    return (
        <div>
            <ReactSVG src={BrandImage} />
        </div>
    );
}

export default function About() {
    const { classes } = useStyles();
    const isXs = useMediaQuery('(max-width: 600px)');
    const isSm = useMediaQuery('(min-width: 481px) and (max-width: 769px)');
    const isMd = useMediaQuery('(min-width: 769px) and (max-width: 1430px)');
    const isLg = useMediaQuery('(min-width: 1440px) and (max-width: 2000px)');
    const isXL = useMediaQuery('(min-width: 2000px) and (max-width: 5000px)');
    const welcomeFontSize = isXs ? "1em" : isLg ? "1.1em" : isXL ? "3em" : "1em"
    const welcomeFontSizeSecond = isXs ? "0.8em" : isLg ? "1em" : isXL ? "2.2em" : "1em"
    const isTabletOrSmaller = useMediaQuery('(max-width: 769)')

    const loveToCodeText = "Here at Ran Web Dev, we are all about web development and SaaS. We eat, sleep, and breathe code! Seriously, it's kind of a problem..but hey, at least it means we're really good at what we do. So if you need a team of passionate, slightly obsessive developers to build you something amazing, you have come to the right place."
    const buildRelationshipsText = "We believe that the key to success is building strong relationships with our customers, and we're committed to doing just that. We love to stay in touch and get to know our clients, so that we can understand their unique needs and deliver the best possible solutions. Let's build something great together!"
    const deliverOnTime = "We love to keep in touch with our customers and our team, and we're pretty sure we're more reliable than the post office. With our superpowers of communication and organization, we always manage to deliver on time – even if we have to use carrier pigeons to do it. So if you want a development team that's on the ball (and on the bird), look no further!"
    return <>
        <Flex
            align="center"
            className={classes.section}
            justify="center"
            wrap="wrap"
            mt={isXs ? 40 : 15}
            mx={50}
            mb={60}
            style={{ maxWidth: "100%" }}
        >
            <div style={{ flex: 1, minWidth: "250px", justifyContent: "center", padding: isXs ? "1rem" : "3rem", maxWidth: isTabletOrSmaller ? "26%" : "100%" }}>
                <div style={{ display: "flex", flexDirection: isXs || isSm ? "column-reverse" : "column" }}>
                    <Image src={RoadMapImage} style={{ padding: "0.5em" }} />
                    <Text color="white" size="lg" style={{ fontFamily: "Arial Rounded MT Bold", fontSize: welcomeFontSize }} mx={10} mb={5}>
                        You are exactly where you should be, welcome to <span style={{ fontWeight: "bold" }}>Ran Web Dev</span>!
                    </Text>
                </div>
                <Text color="white" size="lg" style={{ fontFamily: "Arial Rounded MT Bold", fontSize: welcomeFontSizeSecond }} mx={10} mt={isXs ? 30 : 0}>
                    We're like the Robin to your Batman, the peanut butter to your jelly, the frontend to your Saas - we just make everything better.
                </Text>
            </div>
            <div style={{ flex: 1, minWidth: "250px" }}>
                <Animation />
            </div>
        </Flex>

        <Flex mx={60} pb={20} justify="center" id="about">
            <Title size="h1" className={classes.aboutTitle}>About</Title>
        </Flex>
        
        {isMd ? (
        <Carousel slideSize="50%" height={600} slideGap="md" loop withIndicators my={40}>
            <Carousel.Slide>
                <Flex direction="column" className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "5rem"}>
                    <Title size="h1" sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Passion</Title>
                    <Image src={DeveloperImage} mb={30} width={200} />
                    <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>{loveToCodeText}</Text>
                </Flex>
            </Carousel.Slide>
            <Carousel.Slide>
                <Flex direction="column" className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "5rem"}>
                    <Title size="h1" sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Communication</Title>
                    <Image src={StayInTouchImage} px={10} width={220} />
                    <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>{buildRelationshipsText}</Text>
                </Flex>
            </Carousel.Slide>
            <Carousel.Slide>
                <Flex direction="column" className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "5rem"}>
                    <Title size="h1" sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Trust</Title>
                    <Image src={GetThingsDoneImage} px={10} width={200} />
                    <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>{deliverOnTime}</Text>
                </Flex>
            </Carousel.Slide>
        </Carousel>) :
         (<Flex justify="space-between" columnGap={20} rowGap={20} mx={40} direction={isXs || isSm ? "column" : "row"}>
            <Flex direction="column" maw={isTabletOrSmaller ? "26%" : "100%"} className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "3rem"}>
                <Title size={isXs ? "h2" : "h1"} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Passion</Title>
                <Image src={DeveloperImage} mb={30} width={220} />
                <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold", fontSize: isXs ? "0.7em" : "1em" }}>{loveToCodeText}</Text>
            </Flex>
            <Flex direction="column" maw={isTabletOrSmaller ? "26%" : "100%"} className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "3rem"}>
                <Title size={isXs ? "h2" : "h1"} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Communication</Title>
                <Image src={StayInTouchImage} px={10} width={220} />
                <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold", fontSize: isXs ? "0.7em" : "1em" }}>{buildRelationshipsText}</Text>
            </Flex>
            <Flex direction="column" maw={isTabletOrSmaller ? "26%" : "100%"} className={classes.section} justify="center" align="center" p={isXs ? "1rem" : "3rem"}>
                <Title size={isXs ? "h2" : "h1"} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold" }}>Trust</Title>
                <Image src={GetThingsDoneImage} px={10} width={220} />
                <Text sx={{ color: "white", fontFamily: "Arial Rounded MT Bold", fontSize: isXs ? "0.7em" : "1em" }}>{deliverOnTime}</Text>
            </Flex>
        </Flex>)}
    </>

}
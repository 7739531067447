import React from "react";
import {createStyles, Text} from "@mantine/core";
import Header from "./components/Header";
import About from "./components/About";
import Skills from "./components/Skills";
import Contact from "./components/Contact";
const useStyles = createStyles((theme) => ({
  body : {
    backgroundColor: "#111927",
    backgroundImage: " radial-gradient(at 47% 33%, hsl(215.89, 96%, 46%) 0, transparent 59%),radial-gradient(at 82% 65%, hsl(313.98, 95%, 21%) 0, transparent 55%)",
    minHeight: "100vh"
  }
}));

function App() {
  const { classes } = useStyles();
  return (
    <div className={classes.body} >
      <Header/>
      <About/>
      <Skills/>
      <Contact/>
    </div>
  );
}

export default App;

import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Text, Image, Flex, Title, Grid } from '@mantine/core';
import SkillsImg from "../images/Skills/skillsImg.png"


const useStyles = createStyles(theme => ({
    section: {
        backgroundColor: 'rgba(17, 25, 40, 0.10)',
        borderRadius: 12,
        border: '1px solid rgba(255, 255, 255, 0.125)',
        backdropFilter: 'blur(16px) saturate(180%)',
        WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    },
    skillsTitle: {
        color: "white",
        fontFamily: "Arial Rounded MT Bold",
        textTransform: "uppercase",
    }
}));

export default function Skills() {
    const { classes } = useStyles();
    const isXs = useMediaQuery('(max-width: 600px)');
    const isSm = useMediaQuery('(min-width: 481px) and (max-width: 769px)');
    const isMd = useMediaQuery('(min-width: 769px) and (max-width: 1430px)');
    const isLg = useMediaQuery('(min-width: 1440px) and (max-width: 2000px)');
    const isXL = useMediaQuery('(min-width: 2000px) and (max-width: 5000px)');
    const isTabletOrSmaller = useMediaQuery('(max-width: 1070px)');
    const frontendSkills = [
        'HTML',
        'CSS',
        'JavaScript',
        'TypeScript',
        'SAPUI5',
        'Fiori Elements',
        'React',
        'Mantine',
        'Bootstrap',
        'jQuery'
      ];

    const backendSKills = [
        'NodeJS',
        'Express',
        'MySQL',
        'MongoDB',
        'Mongoose',
        'OData',
        'RestAPI',
    ]

    return <>
        <Flex mx={60} pb={20} justify="center" mt={50} mb={20} id="skills">
            <Title size="h1" className={classes.skillsTitle}>Skills</Title>
        </Flex>

        <Flex pb={20} justify= {isTabletOrSmaller ? "center" : "space-between"} className={classes.section} mx={50} direction={isTabletOrSmaller ? "column" : "row"}>
            <Flex display="column" sx={{flexDirection: "column"}} m={35} align="center">
                <Title size="h2" mb={20} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold"}}>Frontend</Title>
                {frontendSkills.map((skill, index) => (<Text key={index} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold", fontSize: isXs ? "1em" : "1.5em" }} mr={10}>{skill}</Text>))}
            </Flex>
            <Flex justify="center">
                <Image src={SkillsImg} width={ isXs ? "20rem" : "31.25rem"}></Image>
            </Flex>
            <Flex sx={{flexDirection: "column"}} mx={50} my={35}  align="center">
                <Title size="h2" mb={20}  sx={{ color: "white", fontFamily: "Arial Rounded MT Bold"}}>Backend</Title>
                {backendSKills.map((skill, index) => (<Text key={index} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold", fontSize: isXs ? "1em" : "1.5em" }}>{skill}</Text>))}
            </Flex>
        </Flex>
    </>
}
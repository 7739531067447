import React from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles, Text, Image, Flex, Title, Grid } from '@mantine/core';
import LinkedInImage from "../images/Contact/linkedInImage.png";
import MailImage from "../images/Contact/mailImage.png"


const useStyles = createStyles(theme => ({
    section: {
        backgroundColor: 'rgba(17, 25, 40, 0.10)',
        borderRadius: 12,
        border: '1px solid rgba(255, 255, 255, 0.125)',
        backdropFilter: 'blur(16px) saturate(180%)',
        WebkitBackdropFilter: 'blur(16px) saturate(180%)',
    },
    contactTitle: {
        color: "white",
        fontFamily: "Arial Rounded MT Bold",
        textTransform: "uppercase",
    },

    anchor: {
        textDecoration: "none",
        transition: "background-color 0.2s ease-in-out",
        backgroundColor: "transparent",
    
        "&:hover": {
          transform: "translateY(-3px)"
        }
      }
    
}));

export default function Skills() {
    const { classes } = useStyles();
    const isXs = useMediaQuery('(max-width: 600px)');
    const isSm = useMediaQuery('(min-width: 481px) and (max-width: 769px)');
    const isMd = useMediaQuery('(min-width: 769px) and (max-width: 1430px)');
    const isLg = useMediaQuery('(min-width: 1440px) and (max-width: 2000px)');
    const isXL = useMediaQuery('(min-width: 2000px) and (max-width: 5000px)');
    const isTabletOrSmaller = useMediaQuery('(max-width: 1070px)');

    return <>
        <Flex mx={60} pb={20} justify="center" mt={50} mb={20} id="contact">
            <Title size="h1" className={classes.contactTitle}>Contact us</Title>
        </Flex>
        <Flex mx={60} direction={isXs || isSm ? "column" : "row"} justify="center" pb={40}>
            <a href="https://www.linkedin.com/company/ran-web-dev/" target="_blank" rel="norefferer" className={classes.anchor}>
                <Flex direction="row" align="center" mr={isXs ? 0 : 20} justify="center">
                    <Image src={LinkedInImage} width={isXs ? 50 : 60} />
                    <Title size={isXs ? "h5" : "h3"} sx={{ color: "white", fontFamily: "Arial Rounded MT Bold"}}>Let's connect!</Title>
                </Flex>
            </a>
            <a href="mailto:calin@ranwebdev.com" target="_blank" rel="norefferer"  className={classes.anchor}>
                <Flex direction="row" align="center" justify="center">
                    <Image src={MailImage} width={isXs ? 50 : 60} />
                    <Title  size={isXs? "h5" : "h3"} sx={{color:"white", fontFamily: "Arial Rounded MT Bold"}}>Let's discuss!</Title>
                </Flex>
            </a>
        </Flex>
    </>
}